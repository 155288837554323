html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

body {
  margin: 0;
  margin-bottom: 16px;
}

h1 {
  font-size: 40px;
  margin: 8px;
  text-align: center;
}

h2 {
  font-size: 36px;
  margin: 8px;
}

h3 {
  font-size: 22px;
  margin-bottom: 4px;
  text-align: center;
}

p {
  font-size: 18px;
  margin-top: 0.67em;
  letter-spacing: -0.003em;
  line-height: 28px;
}

a {
  text-decoration: none;
}

b {
  font-size: 20px;
}

.h {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.c {
  width: 100%;
  max-width: 466px;
  margin: auto;
  margin-bottom: 8px;
  box-sizing: border-box;
  padding: 0 12px;
}

.c > p {
  text-align: center;
}

.c > h2 {
  text-align: center;
}

.a {
  margin-left: 4px;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.hdr {
  top: 0;
  position: sticky;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  background-color: white;
  height: 56px;
  align-items: center;
  margin-bottom: 24px;
  max-width: 100vw;
  padding: 0 8px;
}

.hd {
  margin: 0;
}

.link {
  color: black;
}

.lnk {
  color: black;
  padding: 0 8px;
}
